import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LogoStrip from "../components/logo-strip"
import Logo from "../components/logo"
import Footer from "../components/Footer"

const IndexPage = () => (
  <Layout pageInfo={{ pageName: "index" }} logo={<Logo />} isHome={true}>
    <SEO title="Home" keywords={[`Viglietti Lifestyle Estate`]} />
    <div class="container-fluid">
      <div class="row">
        <div class="col-3 col-md-4 col-lg featured-index p-0">
          <Link to="/miramonti">
            <figure class="featured-text">
              <h3>
                <Link className="text-white" to="/miramonti">
                  Miramonti
                  <br /> Olive Oil
                </Link>
              </h3>
            </figure>
            <StaticImage
              class="img-fluid featured"
              src="../images/miramonti.webp"
              alt="Miramonti Olive Oil"
            />
          </Link>
        </div>

        <div class="col-3 col-md-4 col-lg featured-index p-0">
          <Link to="/wine">
            <figure class="featured-text">
              <h3>
                <Link className="text-white" to="/wine">
                  Miramonti Wine
                </Link>
              </h3>
            </figure>
            <StaticImage
              class="img-fluid featured"
              src="../images/wine.webp"
              alt="Miramonti Wine"
            />
          </Link>
        </div>

        <div class="col-3 col-md-4 col-lg featured-index p-0">
          <Link to="https://theitalianjobbrewery.co.za">
            <figure class="featured-text">
              <h3>
                <Link
                  className="text-white"
                  to="https://theitalianjobbrewery.co.za"
                >
                  The Italian Job Brewery
                </Link>
              </h3>
            </figure>
            <StaticImage
              class="img-fluid featured"
              src="../images/tijb.webp"
              alt="The Italian Job Brewery"
            />
          </Link>
        </div>

        <div class="col-3 col-md-4 col-lg featured-index p-0">
          <Link to="/beer">
            <figure class="featured-text">
              <h3>
                <Link className="text-white" to="/beer">
                  Beer Garden
                </Link>
              </h3>
            </figure>
            <StaticImage
              class="img-fluid featured"
              src="../images/beer-garden.webp"
              alt="The Italian Job Beer Garden"
            />
          </Link>
        </div>

        <div class="col-3 col-md-4 col-lg featured-index p-0">
          <Link to="/cars">
            <figure class="featured-text">
              <h3>
                <Link className="text-white" to="/cars">
                  Cars
                </Link>
              </h3>
            </figure>
            <StaticImage
              class="img-fluid featured"
              src="../images/cars.webp"
              alt="Viglietti Customs and Restorations"
            />
          </Link>
        </div>

        <div class="col-3 col-md-4 col-lg featured-index p-0">
          <Link to="/equipment">
            <figure class="featured-text">
              <h3>
                <Link className="text-white" to="/equipment">
                Brewing, Olive Oil and Wine Equipment
                </Link>
              </h3>
            </figure>
            <StaticImage
              class="img-fluid featured"
              src="../images/Equipment/VLE_EQUIPMENT_1200x1200-01.webp"
              alt="Viglietti Industries Brewing Equipment"
            />
          </Link>
        </div>

        <div class="col-3 col-md-4 col-lg featured-index p-0">
          <Link to="/manufacturing">
            <figure class="featured-text">
              <h3>
                <Link className="text-white" to="/manufacturing">
                  Stainless Steel Manufacturing
                </Link>
              </h3>
            </figure>
            <StaticImage
              class="img-fluid featured"
              src="../images/equipment.webp"
              alt="Viglietti Industries Stainless Manufacturing"
            />
          </Link>
        </div>
      </div>
    </div>

    <LogoStrip />
    <Footer />
  </Layout>
)

export default IndexPage
